'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { ArrowTurnDownLeftIcon, ArrowTurnDownRightIcon, CakeIcon, ChartBarIcon, IdentificationIcon, ListBulletIcon, MapIcon, PaperClipIcon, ShieldCheckIcon, UserCircleIcon, UserIcon } from '@heroicons/react/20/solid'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'

import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'



const userSesion = [
  { nombre: 'Marcos', apellido: 'Lazo' },
]

const navigation = [
  { name: 'Registro', href: '#', icon: IdentificationIcon, current: false },
  { name: 'Usuarios', href: '/Users', icon: UserIcon, current: false },
  { name: 'Alojamientos', href: '/Booking', icon: CalendarIcon, current: false },
  { name: 'Despensa', href: '#Stock', icon: CakeIcon, current: false },
  { name: 'Custodia', href: '/Storage', icon: ShieldCheckIcon, current: false },
  { name: 'Moviliizacion', href: '/Vehicles', icon: MapIcon, current: false },
  { name: 'Metricas', href: '#', icon: ChartBarIcon, current: false },
]


const basicUserInfo = [

  { email: 'camila@castros.cl', 
    nombre: 'camila',
    apellidoPaterno: 'castro',
    apellidoMaterno: 'vergara',
    rut: '99.9999.999-9',
    perfil:'administrador',
    hospedaje: 'avenida avenida',
    mesa:'5',
    auto: 'patente',
    custodia: 'xxxxxxxx',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      {/* Mobile sidebar */}
      <div>
        <button
          type="button"
          className="lg:hidden fixed top-4 left-4 z-40 inline-flex items-center justify-center rounded-md p-2.5 text-gray-600 hover:bg-gray-800 hover:text-white"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>

        <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"
          />
          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                <li className="-mx-6 mt-auto">
                  <a
                    href="/UserProfile"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <UserIcon
                      className="h-10 w-10 rounded-full bg-gray-800"
                    />

                    <span className="sr-only">Your profile</span>
                    {userSesion.map((item) => (

                      <span aria-hidden="true">{item.nombre} {item.apellido}</span>
                    )
                    )
                    }
                  </a>
                </li>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <li className="-mx-6 mt-auto">
              <a
                href="/UserProfile"
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
              >
                <UserIcon
                  className="h-10 w-10 rounded-full bg-gray-800"
                />
                <span className="sr-only">Your profile</span>
                {userSesion.map((item) => (

                  <span aria-hidden="true">{item.nombre} {item.apellido}</span>
                )
                )
                }
              </a>
            </li>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="lg:pl-72">
        <main>
          <div className="relative">
            <div className="mx-auto max-w-3xl py-4">
              <div className="text-center">
                <h1 className="text-3xl font-bold">Perfil de Usuario</h1>
                <p className="mt-2 text-lg text-gray-600"> </p>
              </div>
                <div>
                  <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Informacion Basica
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
                  </div>

                  {basicUserInfo.map((item) => (

                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-700">Usuario</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.email}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nombre y apellido</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.nombre} {item.apellidoPaterno} {item.apellidoMaterno}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">RUT</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.rut}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Tipo Perfil</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.perfil}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Hospedaje Asignado</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.hospedaje}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Mesa asignada</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.mesa}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Auto Asignado</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.auto}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Custodia</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.custodia}</dd>
                      </div>
                    </dl>
                  </div>
                   ))}

                </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}