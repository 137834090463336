'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { IdentificationIcon, PaperClipIcon, UserCircleIcon, UserIcon, CalendarIcon, CakeIcon, ShieldCheckIcon, MapIcon, ChartBarIcon } from '@heroicons/react/20/solid'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, PhotoIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';


import {
    Bars3Icon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'


const navigation = [
  { name: 'Registro', href: '#', icon: IdentificationIcon, current: false },
  { name: 'Usuarios', href: '/Users', icon: UserIcon, current: false },
  { name: 'Alojamientos', href: '/Booking', icon: CalendarIcon, current: false },
  { name: 'Despensa', href: '#Stock', icon: CakeIcon, current: false },
  { name: 'Custodia', href: '/Storage', icon: ShieldCheckIcon, current: false },
  { name: 'Moviliizacion', href: '/Vehicles', icon: MapIcon, current: false },
  { name: 'Metricas', href: '#', icon: ChartBarIcon, current: false },
]

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

const stats = [
  { id: 1, name: 'Total Subscribers', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase' },
  { id: 2, name: 'Avg. Open Rate', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
  { id: 3, name: 'Avg. Click Rate', stat: '24.57%', icon: CursorArrowRaysIcon, change: '3.2%', changeType: 'decrease' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      {/* Mobile sidebar */}
      <div>
        <button
          type="button"
          className="lg:hidden fixed top-4 left-4 z-40 inline-flex items-center justify-center rounded-md p-2.5 text-gray-600 hover:bg-gray-800 hover:text-white"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>

        <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"
          />
          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                className="h-8 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=48&h=48"
                      className="h-6 w-6 rounded-full bg-gray-800"
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Marvin McKinney</span>
                    <PaperClipIcon className="h-6 w-6" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="lg:pl-72">
        <main>
          <div className="relative">
            <div className="mx-auto max-w-3xl py-4">
              <div className="text-center">
                <h1 className="text-3xl font-bold">Agregar Hogar</h1>
                <p className="mt-2 text-lg text-gray-600"> </p>
              </div>
              {
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <form >
                      <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">Informacion del dueño</h2>
                        </div>

                        <div className="mt-10 grid grid-cols- gap-x-6 gap-y-8 sm:grid-cols-6">

                          <div className="sm:col-span-3">
                            <label htmlFor="RUT" className="block text-sm font-medium leading-6 text-gray-900">
                              RUT
                            </label>
                            <div className="mt-2">
                              <input
                                id="RUT"
                                name="RUT"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Nombre" className="block text-sm font-medium leading-6 text-gray-900">
                              Nombre
                            </label>
                            <div className="mt-2">
                              <input
                                id="Nonmbre"
                                name="Nombre"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Apellido-Paterno" className="block text-sm font-medium leading-6 text-gray-900">
                              Apellido Paterno
                            </label>
                            <div className="mt-2">
                              <input
                                id="Apellido-Paterno"
                                name="Apellido-Paterno"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Apellido-Materno" className="block text-sm font-medium leading-6 text-gray-900">
                              Apellido Materno
                            </label>
                            <div className="mt-2">
                              <input
                                id="Apellido-Materno"
                                name="Apellido-Materno"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Iglesia" className="block text-sm font-medium leading-6 text-gray-900">
                              Iglesia
                            </label>
                            <div className="mt-2">
                              <input
                                id="Iglesia"
                                name="Iglesia"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Acreditado" className="block text-sm font-medium leading-6 text-gray-900">
                              Acreditado
                            </label>
                            <div className="mt-2">
                              <input
                                id="Acreditado"
                                name="Acreditado"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="Grado" className="block text-sm font-medium leading-6 text-gray-900">
                              Grado
                            </label>
                            <div className="mt-2">
                              <select
                                id="Grado"
                                name="Grado"
                                autoComplete="country-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option>Presbítero</option>
                                <option>Diácono</option>
                                <option>Probando</option>
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="Zona" className="block text-sm font-medium leading-6 text-gray-900">
                              Zona
                            </label>
                            <div className="mt-2">
                              <input
                                id="Zona"
                                name="Zona"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>



                          <div className="sm:col-span-2">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                              Country
                            </label>
                            <div className="mt-2">
                              <select
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option>Chile</option>
                                <option>Chile2</option>
                                <option>Chile3</option>
                              </select>
                            </div>
                          </div>

                        </div>

                        <div className="mt-10 space-y-10">
                          <fieldset>
                            <div className="mt-6 space-y-6">
                              <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="Hipertension"
                                    name="Hipertension"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Hipertension" className="font-medium text-gray-900">
                                    Hipertension
                                  </label>
                                  <p className="text-gray-500">Seleccionar si tiene Hipertension.</p>
                                </div>


                                <div className="flex h-6 items-center">
                                  <input
                                    id="Diabetes"
                                    name="Diabetes"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Diabetes" className="font-medium text-gray-900">
                                    Diabetes
                                  </label>
                                  <p className="text-gray-500">Seleccionar si tiene Diabetes.</p>
                                </div>


                                <div className="flex h-6 items-center">
                                  <input
                                    id="Celiaco"
                                    name="Celiaco"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Celiaco" className="font-medium text-gray-900">
                                    Celiaco
                                  </label>
                                  <p className="text-gray-500">Seleccionar si es Celiaco.</p>
                                </div>

                              </div>
                              <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="Vehiculo"
                                    name="Vehiculo"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Vehiculo" className="font-medium text-gray-900">
                                    Vehiculo Propio
                                  </label>
                                  <p className="text-gray-500">Seleccionar si viene en vehiculo propio.</p>
                                </div>
                              </div>
                              <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="Esposa"
                                    name="Esposa"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Esposa" className="font-medium text-gray-900">
                                    Esposa
                                  </label>
                                  <p className="text-gray-500">Seleccionar si el pastor viene con esposa.</p>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">Informacion Habitaciones</h2>
                          <p className="mt-1 text-sm leading-6 text-gray-600">
                          </p>

                        </div>

                        <div className="mt-10 grid grid-cols- gap-x-6 gap-y-8 sm:grid-cols-6">

                          <div className="sm:col-span-3">
                            <label htmlFor="RUT" className="block text-sm font-medium leading-6 text-gray-900">
                              RUT
                            </label>
                            <div className="mt-2">
                              <input
                                id="RUT"
                                name="RUT"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Nombre" className="block text-sm font-medium leading-6 text-gray-900">
                              Nombre
                            </label>
                            <div className="mt-2">
                              <input
                                id="Nonmbre"
                                name="Nombre"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Apellido-Paterno" className="block text-sm font-medium leading-6 text-gray-900">
                              Apellido Paterno
                            </label>
                            <div className="mt-2">
                              <input
                                id="Apellido-Paterno"
                                name="Apellido-Paterno"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label htmlFor="Edad" className="block text-sm font-medium leading-6 text-gray-900">
                              Edad
                            </label>
                            <div className="mt-2">
                              <input
                                id="Edad"
                                name="Edad"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>



                        <div className="mt-10 space-y-10">
                          <fieldset>
                            <div className="mt-6 space-y-6">
                              <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="Hipertension"
                                    name="Hipertension"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Hipertension" className="font-medium text-gray-900">
                                    Hipertension
                                  </label>
                                  <p className="text-gray-500">Seleccionar si tiene Hipertension.</p>
                                </div>


                                <div className="flex h-6 items-center">
                                  <input
                                    id="Diabetes"
                                    name="Diabetes"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Diabetes" className="font-medium text-gray-900">
                                    Diabetes
                                  </label>
                                  <p className="text-gray-500">Seleccionar si tiene Diabetes.</p>
                                </div>


                                <div className="flex h-6 items-center">
                                  <input
                                    id="Celiaco"
                                    name="Celiaco"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor="Celiaco" className="font-medium text-gray-900">
                                    Celiaco
                                  </label>
                                  <p className="text-gray-500">Seleccionar si es Celiaco.</p>
                                </div>

                              </div>

                            </div>
                          </fieldset>
                        </div>

                      </div>

                      <div className="border-b border-gray-900/10 pb-12">
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                        </p>

                      </div>

                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Eliminar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              }
            </div>
          </div>
        </main>
      </div>
    </>
  )
}