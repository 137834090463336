'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { IdentificationIcon, PaperClipIcon, UserCircleIcon, UserIcon, CalendarIcon, CakeIcon, ShieldCheckIcon, MapIcon, ChartBarIcon } from '@heroicons/react/20/solid'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, PhotoIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';


import {
    Bars3Icon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Registro', href: '#', icon: IdentificationIcon, current: false },
  { name: 'Usuarios', href: '/Users', icon: UserIcon, current: false },
  { name: 'Alojamientos', href: '/Booking', icon: CalendarIcon, current: false },
  { name: 'Despensa', href: '#Stock', icon: CakeIcon, current: false },
  { name: 'Custodia', href: '/Storage', icon: ShieldCheckIcon, current: false },
  { name: 'Moviliizacion', href: '/Vehicles', icon: MapIcon, current: false },
  { name: 'Metricas', href: '#', icon: ChartBarIcon, current: false },
]
const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

const stats = [
    { id: 1, name: 'Total Subscribers', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Avg. Open Rate', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Avg. Click Rate', stat: '24.57%', icon: CursorArrowRaysIcon, change: '3.2%', changeType: 'decrease' },
  ]

const Properties = [
  { name: 'CASA UNA', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  { name: 'Michael Scott', title: 'Regional Manager', email: 'michael.scott@dundermifflin.com', role: 'Admin' },
  { name: 'Pam Beesly', title: 'Office Administrator', email: 'pam.beesly@dundermifflin.com', role: 'Member' },
  { name: 'Jim Halpert', title: 'Sales Representative', email: 'jim.halpert@dundermifflin.com', role: 'Member' },
  { name: 'Dwight Schrute', title: 'Assistant Regional Manager', email: 'dwight.schrute@dundermifflin.com', role: 'Admin' },
  { name: 'Stanley Hudson', title: 'Sales Representative', email: 'stanley.hudson@dundermifflin.com', role: 'Member' },
  { name: 'Angela Martin', title: 'Accountant', email: 'angela.martin@dundermifflin.com', role: 'Member' },
  { name: 'Kevin Malone', title: 'Accountant', email: 'kevin.malone@dundermifflin.com', role: 'Member' },
  { name: 'Toby Flenderson', title: 'HR Representative', email: 'toby.flenderson@dundermifflin.com', role: 'Admin' },
  { name: 'Kelly Kapoor', title: 'Customer Service Rep', email: 'kelly.kapoor@dundermifflin.com', role: 'Member' },
  { name: 'Ryan Howard', title: 'Temp', email: 'ryan.howard@dundermifflin.com', role: 'Member' },
  { name: 'Oscar Martinez', title: 'Accountant', email: 'oscar.martinez@dundermifflin.com', role: 'Admin' },
  { name: 'Phyllis Vance', title: 'Sales Representative', email: 'phyllis.vance@dundermifflin.com', role: 'Member' },
  { name: 'Creed Bratton', title: 'Quality Assurance', email: 'creed.bratton@dundermifflin.com', role: 'Member' },
  { name: 'Darryl Philbin', title: 'Warehouse Manager', email: 'darryl.philbin@dundermifflin.com', role: 'Admin' },
  { name: 'Meredith Palmer', title: 'Supplier Relations', email: 'meredith.palmer@dundermifflin.com', role: 'Member' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      {/* Mobile sidebar */}
      <div>
        <button
          type="button"
          className="lg:hidden fixed top-4 left-4 z-40 inline-flex items-center justify-center rounded-md p-2.5 text-gray-600 hover:bg-gray-800 hover:text-white"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>

        <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"
          />
          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                className="h-8 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=48&h=48"
                      className="h-6 w-6 rounded-full bg-gray-800"
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Marvin McKinney</span>
                    <PaperClipIcon className="h-6 w-6" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="lg:pl-72">
        <main>
          <div className="relative">
            <div className="mx-auto max-w-3xl py-4">
              <div className="text-center">
                <h1 className="text-3xl font-bold">Alojamientos</h1>
                <p className="mt-2 text-lg text-gray-600"> </p>
            </div>
                {
                    <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Propiedades</h1>
                        <p className="mt-2 text-sm text-gray-700">
                          A list of all the users in your account including their name, title, email and role.
                        </p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Add user
                        </button>
                      </div>
                    </div>
                    <div className="-mx-4 mt-8 sm:-mx-0">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Name
                            </th>
                            <th
                              scope="col"
                              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                              Email
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Role
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {Properties.map((person) => (
                            <tr key={person.email}>
                              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                {person.name}
                                <dl className="font-normal lg:hidden">
                                  <dt className="sr-only">Title</dt>
                                  <dd className="mt-1 truncate text-gray-700">{person.title}</dd>
                                  <dt className="sr-only sm:hidden">Email</dt>
                                  <dd className="mt-1 truncate text-gray-500 sm:hidden">{person.email}</dd>
                                </dl>
                              </td>
                              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{person.title}</td>
                              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{person.email}</td>
                              <td className="px-3 py-4 text-sm text-gray-500">{person.role}</td>
                              <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                  Ver<span className="sr-only">, {person.name}</span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                }
            </div>
          </div>
      </main>
    </div>
    </>  
  )
}