import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import UserProfile from "./components/UserProfile";
import AddUser from "./components/AddUser"; 
import Users from "./components/Users";
import Booking from "./components/Booking";
import AddBooking from "./components/AddBooking";
import EditUser from "./components/EditUser";
import Vehicles from "./components/Vehicles";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/UserProfile" element={<UserProfile />} />
        <Route path="/AddUser" element={<AddUser />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/Booking" element={<Booking />} />
        <Route path="/AddBooking" element={<AddBooking />} />
        <Route path="/EditUser" element={<EditUser />} />
        <Route path="/Vehicles" element={<Vehicles />} />
      </Routes>
    </Router>
  );
};

export default App;